import { styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';

const StyledImage = styled( 'img' )( {} );
// const StyledNextImage = styled( Image )( {} );

export default StyledImage;

export function NextImage( { ...props }: ImageProps ) {
	return (
		<Image
			alt={props.alt || ''}
			src={props.src}
			loader={( { src, width, quality } ) => `${src}?w=${width}&q=${quality || 75}`}
			{...props}
		/>
	);
}
